import React, { useState,useEffect } from 'react';
import config from '../../config';
import { Button, Divider, Form, Select } from 'semantic-ui-react';
import Input from './Input';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import './styles.css';


const StepsForMerchant = props => {
     const [loading, setLoading] = useState(false);
     const [customer, setCustomer] = useState();
     const [merchant, setMerchant] = useState();
     const [selectFormError, setSelectFormError] = useState(false);
     const [selectedMNOID, setSelectedMNOID] = useState();
     const [selectedBankBranch, setSelectedBankBranch] = useState();
     const [mnoValue, setMnoValue] = useState('Mobile Operator');
     const [ibanValue, setIbanValue] = useState('Choose Iban');
     const [selectedIbanID, setSelectedIbanID] = useState();
     const [bankBranchValue, setBankBranchValue] = useState('Bank Branch');

     const [open, setOpen] = useState(false);
     const [selectedFormID, setSelectedFormID] = useState();
     const [selectedSchemaID, setSelectedSchemaID] = useState();
     const [schemaValue, setSchemaValue] = useState('Commission Schema');
     const [legalFormValue, setLegalFormValue] = useState('Legal Form');

     const { step, nextStep, prevStep, closeModal, mnos, bankBranch, commissionSchema, legalForm } = props;

     
     const onCreateMerchantHandler = (values) => {
          setLoading(true);
          fetch(`${config.baseURL}/merchantportal/createmerchantstep2`, {
               method: 'POST',
               headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
               },
               body: JSON.stringify({
                    "name": customer.name,
                    "personalNumber": customer.personalNumber,
                    "msisdn": customer.msisdn,
                    "email": customer.email,
                    "commissionSchemaId": selectedSchemaID,
                    "bankBranchId": selectedBankBranch,
                    "legalFormId": selectedFormID,
                    "mobileNetworkOperatorId": selectedMNOID,
                    "iban": selectedIbanID,
                    "userName": values.userName,
                    "address": values.address,
                    "merchantTradeName": values.merchantTradeName,
                    "birthdate": values.birthdate,
                    "nuis": customer.nuis,
                    "businessName": customer.businessName
               })
          }).then(res => {
               console.log('API Response Status:', res.status);
               return res.json();
          }).then(data => {
               console.log('API Response Data:', data);
               if (data.success) {
                    const dateString = data?.value?.birthDate;
                    const dateObj = new Date(dateString);
                  
                    const day = String(dateObj.getDate()).padStart(2, '0');
                    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
                    const year = dateObj.getFullYear();

                    localStorage.setItem('username', data.value.username);
                    localStorage.setItem('name', data.value.name);
                    localStorage.setItem('birthday', `${day}-${month}-${year}`);
                    localStorage.setItem('personalnumber', data.value.personalNumber);
                    localStorage.setItem('merchantTradeName', data.value.merchantTradeName);
                    localStorage.setItem('mobile', data.value.msisdn);
                    localStorage.setItem('email', data.value.email);
                    localStorage.setItem('address', data.value.address);
                    localStorage.setItem('legalForm', data.value.legalForm);
                    localStorage.setItem('businessName', data.value.businessName);
                    localStorage.setItem('iban', data.value.iban);
                    localStorage.setItem('nuis', data.value.nuis);
                    localStorage.setItem('activationcode', data.value.activationPassword);
                    closeModal();
                    window.open('/form/merchantsubform.html', "_blank");
                    props.refreshHandler();
                    toast.success('Merchant Created Successfully');
                    setLoading(false);
               } else {
                    toast.error(data.messages && data.messages[0]?.text !== "" ? data.messages[0].text : data.description);
                    setLoading(false);
                  }
                })
              };

     const onSelectLegalFormHandler = (e, data) => {
          setLegalFormValue(data.value);
          legalForm.map(form => {
               if (form.name === data.value) {
                    return setSelectedFormID(form.indexId);
               }
          })
     }

     const onSelectMNOHandler = (e, data) => {
          setMnoValue(data.value);
          mnos.map(mno => {
               if (mno.name === data.value) {
                    return setSelectedMNOID(mno.indexId);
               }
          })
     }

     const onSelectSchemaHandler = (e, data) => {
          setSchemaValue(data.value)
          commissionSchema.map(schema => {
               if (schema.name === data.value) {
                    return setSelectedSchemaID(schema.indexId);
               }
          })
     }

     const onSelectBranchHandler = (e, data) => {
          setBankBranchValue(data.value)
          bankBranch.map(branch => {
               if (branch.displayName === data.value) {
                    return setSelectedBankBranch(branch.indexId);
               }
          })
     }

     const onSelectIbanHandler = (e, data) => {
          setIbanValue(data.value);
          customer.ibans.forEach(iban => {
              if (iban === data.value) {
                  setSelectedIbanID(iban);
              }
          });
      };

     const merchantSubFirstStep = (values) => {
          setLoading(true);
          fetch(`${config.baseURL}/merchantportal/createmerchantstep1`, {
               method: 'POST',
               headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
               },
               body: JSON.stringify({
                    "name": values.name,
                    "personalNumber": values.personalNumber,
                    "msisdn": values.msisdn,
                    "email": values.email,
                    "businessName": values.businessName,
                    "nuis": values.nuis
               })
          }).then(res => {
               return res.json();
          }).then(data => {
               if (data.success) {
                    setCustomer(data.value)
                    setMerchant(values)
                    nextStep();
                    setLoading(false);
               } else {
                    toast.error(data.description !== "" ? data.description : data.messages[0].text);
                    setLoading(false);
               }
          })
     };

     switch (step) {
          case 1:
               return (
                    <div style={{ marginTop: 10 }}>
                         <Formik
                              initialValues={{
                                   name: merchant?.name || '',
                                   personalNumber: merchant?.personalNumber || '',
                                   msisdn: merchant?.msisdn || '',
                                   email: merchant?.email || '',
                                   businessName: merchant?.businessName || '',
                                   nuis: merchant?.nuis || ''
                              }} onSubmit={(values, { resetForm }) => {
                                   merchantSubFirstStep(values, resetForm);
                              }}
                              validationSchema={Yup.object().shape({
                                   name: Yup.string()
                                        .required('This field is required.')
                                        .trim(),
                                   personalNumber: Yup.string()
                                        .required('This field is required.')
                                        .trim(),
                                   msisdn: Yup.string()
                                        .required('This field is required.')
                                        .trim(),
                                   email: Yup.string()
                                        .email('Invalid email')
                                        .required('This field is required.')
                                        .trim(),
                                   businessName: Yup.string()
                                        .required('This field is required.')
                                        .trim(),
                                   nuis: Yup.string()
                                        .required('This field is required.')
                                        .trim(),
                              })}
                         >
                              {({ handleChange, handleBlur, errors, touched, handleSubmit }) => (
                                   <React.Fragment>
                                        <Form>
                                             <Form.Group widths='equal'>
                                                  <Form.Field>
                                                       <Form.Input fluid label="Business Legal Name" type='text' defaultValue={merchant?.businessName} name='businessName' placeholder='Business Name' onChange={handleChange} onBlur={handleBlur} />
                                                       {errors.businessName && touched.businessName ? (
                                                            <span className='formik-error-msg'>{errors.businessName}</span>
                                                       ) : null}
                                                  </Form.Field>
                                                  <Form.Field>
                                                       <Form.Input fluid label="Legal.Rep Full Name" type='text' name='name' defaultValue={merchant?.name} placeholder='Name' onChange={handleChange} onBlur={handleBlur} />
                                                       {errors.name && touched.name ? (
                                                            <span className='formik-error-msg'>{errors.name}</span>
                                                       ) : null}
                                                  </Form.Field>
                                             </Form.Group>
                                             <Form.Group widths='equal'>
                                                  <Form.Field>
                                                       <Form.Input fluid label="NUIS" type='text' name='nuis' defaultValue={merchant?.nuis} placeholder='NUIS' onChange={handleChange} onBlur={handleBlur} />
                                                       {errors.nuis && touched.nuis ? (
                                                            <span className='formik-error-msg'>{errors.nuis}</span>
                                                       ) : null}
                                                  </Form.Field>
                                                  <Form.Field>
                                                       <Form.Input fluid label="Legal.Rep Personal Number" type='text' name='personalNumber' defaultValue={merchant?.personalNumber} placeholder='Personal Number' onChange={handleChange} onBlur={handleBlur} />
                                                       {errors.personalNumber && touched.personalNumber ? (
                                                            <span className='formik-error-msg'>{errors.personalNumber}</span>
                                                       ) : null}
                                                  </Form.Field>
                                             </Form.Group>
                                             <Form.Group widths='equal'>
                                                  <Form.Field>
                                                       <Form.Input fluid label="Legal.Rep MSISDN" type='text' name='msisdn' placeholder='MSISDN' defaultValue={merchant?.msisdn} onChange={handleChange} onBlur={handleBlur} />
                                                       {errors.msisdn && touched.msisdn ? (
                                                            <span className='formik-error-msg'>{errors.msisdn}</span>
                                                       ) : null}
                                                  </Form.Field>
                                                  <Form.Field>
                                                       <Form.Input fluid label="Legal.Rep Email" type='email' name='email' placeholder='Email' defaultValue={merchant?.email} onChange={handleChange} onBlur={handleBlur} />
                                                       {errors.email && touched.email ? (
                                                            <span className='formik-error-msg'>{errors.email}</span>
                                                       ) : null}
                                                  </Form.Field>
                                             </Form.Group>
                                        </Form>
                                        <div className="text-right">
                                             <Button loading={loading} primary onClick={handleSubmit}>Next</Button>
                                        </div>
                                   </React.Fragment>
                              )}
                         </Formik>
                    </div>
               );
          case 2:
               return (
                    <div className='mobileView' style={{ marginTop: 10, position: 'relative', height: '660px',}}>
                         <div style={{ maxHeight: '660px' }}>
                              <Formik
                                   initialValues={{ birthdate: '', address: '', userName: '', iban: '', branch: '', schema: '', merchantTradeName: '', form: '', mno: '' }}
                                   onSubmit={(values, { resetForm }) => {
                                      //  onCreateMerchantHandler(values, resetForm);
                                        if (step === 2) {
                                             onCreateMerchantHandler(values, resetForm);
                                          }
                                   }}
                                   validateOnChange={false}
                                   validateOnBlur={false}
                                   validationSchema={Yup.object().shape({
                                        birthdate: Yup.string()
                                             .required('This field is required.')
                                             .trim(),
                                        address: Yup.string()
                                             .required('This field is required.')
                                             .trim(),
                                        userName: Yup.string()
                                             .required('This field is required.')
                                             .trim(),
                                        merchantTradeName: Yup.string()
                                             .required('This field is required.')
                                             .trim(),

                                   })}
                              >
                                   {({ handleChange, handleBlur, errors, touched, handleSubmit }) => (
                                        <React.Fragment>
                                             <Form>
                                                  <Form.Group widths='equal'>
                                                       <Form.Field>
                                                            <label>Merchant IBAN</label>
                                                            <Select placeholder={ibanValue} onBlur={handleBlur} onChange={onSelectIbanHandler} options={customer?.ibans.map(iban => {
                                                                 return { key: iban, value: iban, text: iban };
                                                            })
                                                            } style={{ borderRadius: 0, height: 50, paddingTop: 17 }} />
                                                            {ibanValue === 'Choose Iban' && selectFormError ? (
                                                                 <span className='formik-error-msg'>This field is required.</span>
                                                            ) : null}
                                                       </Form.Field>
                                                       <Form.Field>
                                                            <label>Merchant Legal Form</label>
                                                            <Select placeholder={legalFormValue} onChange={onSelectLegalFormHandler} options={legalForm.map(form => {
                                                                 return { key: form.id, value: form.name, text: form.name };
                                                            })} style={{ borderRadius: 0, height: 50, paddingTop: 17 }} />
                                                            {legalFormValue === 'Legal Form' && selectFormError ? (
                                                                 <span className='formik-error-msg'>This field is required.</span>
                                                            ) : null}
                                                       </Form.Field>
                                                  </Form.Group>

                                                  <Form.Group widths='equal'>
                                                       <Form.Field>
                                                            <Form.Input fluid label="Business Legal Name" type='text' name='businessName' readOnly value={customer?.businessName} placeholder='Business Name' onChange={handleChange} onBlur={handleBlur} />
                                                       </Form.Field>
                                                       <Form.Field>
                                                            <Form.Input fluid label="Legal.Rep Full Name" type='text' readOnly name='name' placeholder='Name' onChange={handleChange} value={customer?.name} onBlur={handleBlur} />
                                                       </Form.Field>
                                                  </Form.Group>

                                                  <Form.Group widths='equal'>
                                                       <Form.Field>
                                                            <Form.Input fluid label="NUIS" type='text' name='nuis' placeholder='NUIS' readOnly value={customer?.nuis} onChange={handleChange} onBlur={handleBlur} />
                                                       </Form.Field>
                                                       <Form.Field>
                                                            <Form.Input fluid label="Legal.Rep Personal Number" type='text' name='personalNumber' readOnly value={customer?.personalNumber} placeholder='Personal Number' onChange={handleChange} onBlur={handleBlur} />
                                                       </Form.Field>
                                                  </Form.Group>
                                                  <Form.Group widths='equal'>
                                                       <Form.Field>
                                                            <Form.Input fluid label="Legal.Rep MSISDN" type='text' name='msisdn' readOnly value={customer?.msisdn} placeholder='MSISDN' onChange={handleChange} onBlur={handleBlur} />
                                                       </Form.Field>
                                                       <Form.Field>
                                                            <Form.Input fluid label="Legal.Rep Email" type='email' name='email' readOnly value={customer?.email} placeholder='Email' onChange={handleChange} onBlur={handleBlur} />
                                                       </Form.Field>
                                                  </Form.Group>
                                                  <Form.Group widths='equal'>
                                                       <Form.Field>
                                                            <Form.Input fluid label="Legal.Rep Birth Date" type='date' name='birthdate' placeholder='Birth Date' onChange={handleChange} onBlur={handleBlur} />
                                                            {errors.birthdate ? (
                                                                 <span className='formik-error-msg'>{errors.birthdate}</span>
                                                            ) : null}
                                                       </Form.Field>
                                                       <Form.Field>
                                                            <label>Legal.Rep Mobile Operator</label>
                                                            <Select placeholder={mnoValue} onChange={onSelectMNOHandler} onBlur={handleBlur} options={mnos.map(mno => {
                                                                 return { key: mno.id, value: mno.name, text: mno.name };
                                                            })
                                                            } style={{ borderRadius: 0, height: 50, paddingTop: 17 }} />
                                                            {mnoValue === 'Mobile Operator' && selectFormError ? (
                                                                 <span className='formik-error-msg'>This field is required.</span>
                                                            ) : null}
                                                       </Form.Field>
                                                  </Form.Group>
                                                  <Form.Group widths='equal'>
                                                       <Form.Field>
                                                            <Form.Input fluid label="Merchant Trade Name" type='text' name='merchantTradeName' placeholder='Merchant Trade Name' onChange={handleChange} value={customer?.merchantTradeName} onBlur={handleBlur} />
                                                            {errors.merchantTradeName ? (
                                                                 <span className='formik-error-msg'>{errors.merchantTradeName}</span>
                                                            ) : null}
                                                       </Form.Field>
                                                       <Form.Field>
                                                            <Form.Input fluid label="Merchant Username" type='text' name='userName' placeholder='Username' onChange={handleChange} onBlur={handleBlur} />
                                                            {errors.userName ? (
                                                                 <span className='formik-error-msg'>{errors.userName}</span>
                                                            ) : null}
                                                       </Form.Field>
                                                  </Form.Group>
                                                  <Form.Group widths='equal'>
                                                       <Form.Field>
                                                            <label>Commission Schema</label>
                                                            <Select placeholder={schemaValue} onChange={onSelectSchemaHandler} onBlur={handleBlur} options={commissionSchema.map(schema => {
                                                                 return { key: schema.id, value: schema.name, text: schema.name };
                                                            })
                                                            } style={{ borderRadius: 0, height: 50, paddingTop: 17 }} />
                                                            {schemaValue === 'Commission Schema' && selectFormError ? (
                                                                 <span className='formik-error-msg'>This field is required.</span>
                                                            ) : null}
                                                       </Form.Field>
                                                       <Form.Field>
                                                            <label>Bank Branch</label>
                                                            <Select placeholder={bankBranchValue} onChange={onSelectBranchHandler} options={bankBranch.map(branch => {
                                                                 return { key: branch.id, value: branch.displayName, text: branch.displayName };
                                                            })} style={{ borderRadius: 0, height: 50, paddingTop: 17 }} />
                                                            {bankBranchValue === 'Bank Branch' && selectFormError ? (
                                                                 <span className='formik-error-msg'>This field is required.</span>
                                                            ) : null}
                                                       </Form.Field>
                                                  </Form.Group>
                                                  <Form.Group widths='equal'>
                                                       <Form.Field>
                                                            <Form.Input fluid label="Merchant Address" type='text' name='address' placeholder='Address' onChange={handleChange} onBlur={handleBlur}style={{ marginTop:'-6px' }} />
                                                            {errors.address ? (
                                                                 <span className='formik-error-msg'>{errors.address}</span>
                                                            ) : null}
                                                       </Form.Field>
                                                       <Form.Field>
                                                       </Form.Field>
                                                  </Form.Group>
                                             </Form>
                                             <div style={{ position: 'relative',  left: 0, width: '100%', padding: '15px', background: '#fff', borderTop: '1px solid #ddd' }}>
                                                  <div style={buttons}>
                                                       <Button primary onClick={prevStep}>Previous</Button>
                                                       <Button primary onClick={(e) => {
                                                            if (ibanValue === 'Choose Iban' || bankBranchValue === 'Bank Branch' || mnoValue === 'Mobile Operator' || schemaValue === 'Commission Schema' || legalFormValue === 'Legal Form') { setSelectFormError(true) }
                                                            handleSubmit()
                                                       }} loading={loading}>Save</Button>
                                                  </div>
                                             </div>
                                        </React.Fragment>
                                   )}
                              </Formik>
                         </div>
                    </div>
               );
          default:
               return null;
     };
};

const form = {
     textAlign: 'left',
     margin: '15px 0'
}

const buttons = {
     display: 'flex',
     justifyContent: 'space-between',
     padding: '0 5px 0 10px'
}

const buttonsConfirm = {
     display: 'flex',
     justifyContent: 'center',
     padding: '0 5px 0 10px'
}
export default StepsForMerchant;